// extracted by mini-css-extract-plugin
export var project = "projects-module--project--1zdcJ";
export var web = "projects-module--web--1qMXT";
export var item1 = "projects-module--item1--30VqE";
export var item2 = "projects-module--item2--1BzhT";
export var itemtech = "projects-module--itemtech--mEZP-";
export var tech = "projects-module--tech--2-_ba";
export var img = "projects-module--img--3c9-u";
export var gitlink = "projects-module--gitlink--1klLK";
export var item3 = "projects-module--item3--3WQe8";
export var gitshow = "projects-module--gitshow--2yTij";